<section class="pt-10 px-2 rounded-t-lg">
  <p class="text-gray-500 tracking-wide text-lg text-center">
    {{ invalidFileText }}
  </p>

  <div class="w-1/2 mx-auto d-inline-block mt-10">
    <coface-button text="Aceptar" color="primary" (clicked)="closeModal()">
    </coface-button>
  </div>
</section>
