import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { SuccessfulComponent } from '../dialogs/successful/successful.component';
import { ModalService } from '../../../commons/services/dialog.service';
import { InvalidFileComponent } from '../invalid-file/invalid-file.component';

@Component({
  selector: 'coface-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss'],
})
export class InputFileComponent implements OnInit, AfterViewInit, OnChanges {
  showFileLoaded: boolean = false;
  fileLoaded: string;
  disableBtnFile: boolean;
  @Input() flagEvent: boolean = false;
  @Output() selectedFile: any = new EventEmitter<any>();
  @Input() optionalParameter: any;
  @Input() textAccept?: string;

  constructor(private dialogService: ModalService) {}

  ngOnInit(): void {}
  changeFileEvent() {
    if (this.showFileLoaded) {
      this.showFileLoaded = !this.showFileLoaded;
      //   this.singleUploadPresenter.coverLetter.reset('');
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes['optionalParameter'].currentValue);
    const change = changes['optionalParameter']?.currentValue;
    if (
      change &&
      (change.id === 7 || change.id === 8) &&
      change.attachments.length
    ) {
      this.disableBtnFile = true;
      return;
    } else {
      this.disableBtnFile = false;
    }
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
  }

  fileEvent(e: Event) {
    let file = (<HTMLInputElement>e.target).files;
    console.log(file);

    if (file != null) {
      const isInvalid = this.invalidFile(file[0]);
      if (isInvalid.invalidSize || isInvalid.invalidExtension) {
        this.dialogService.open(
          {
            invalidText: isInvalid.invalidSize
              ? `El archivo ${file[0].name} excede el límite de tamaño permitido de 50 MB. Por favor, reduzca el tamaño del archivo e intente subirlo nuevamente.`
              : isInvalid.invalidExtension
              ? 'Formato de archivo no admitido. Los archivos comprimidos(ZIP/RAR) no están permitidos. Por favor, seleccione un formato de archivo compatible e intente nuevamente la carga.'
              : '',
          },
          InvalidFileComponent,
          {
            size: 'SMALL',
            closable: '',
            disableClose: false,
          }
        );
        return;
      }
      if (!this.doesNotHaveConsecutiveSpecialCharacters(file[0]!.name)) {
        this.dialogService.open(
          {},
          SuccessfulComponent,

          {
            size: 'SMALL',
            closable: '',
            disableClose: false,
          }
        );
      } else {
        this.selectedFile.emit(file);
        this.fileLoaded = file[0]!.name;
        if (this.flagEvent == false) {
          this.showFileLoaded = true;
        }
        {
          this.showFileLoaded = false;
        }
      }
    }
  }

  doesNotHaveConsecutiveSpecialCharacters(filename) {
    // Regular expression pattern to match filenames without consecutive special characters
    const pattern =
      /^(?!.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{2})[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\s]+$/;

    return pattern.test(filename);
  }

  private invalidFile(file: File): {
    invalidSize: boolean;
    invalidExtension: boolean;
  } {
    if (!this.validateFileSize50MB(file)) {
      return {
        invalidSize: true,
        invalidExtension: false,
      };
    }
    if (!this.validateFileExtension(file)) {
      return {
        invalidSize: false,
        invalidExtension: true,
      };
    }

    return {
      invalidSize: false,
      invalidExtension: false,
    };
  }

  private validateFileSize50MB(file: File) {
    const fileSizeInMB = file.size / 1024 / 1024;
    if (fileSizeInMB > 50) {
      return false;
    }
    return true;
  }

  private validateFileExtension(file: File) {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (fileExtension === 'zip' || fileExtension === 'rar') {
      return false;
    }
    return true;
  }
}

// function doesNotHaveConsecutiveSpecialCharacters(filename) {
//   // Regular expression pattern to match filenames without consecutive special characters
//   const pattern = /^(?!.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{2})[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;

//   return pattern.test(filename);
// }

// // Example usage
// const filenames = [
//   "file_with_special!.txt",
//   "no_special_character.txt",
//   "file_with__consecutive_special_characters.txt",
//   "file_with_single_special_character.txt"
// ];

// filenames.forEach(filename => {
//   const isValid = doesNotHaveConsecutiveSpecialCharacters(filename);
//   console.log(`${filename} has consecutive special characters: ${!isValid}`);
// });
