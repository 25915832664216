<div
  class="input flex justify-between relative cursor-pointer bg-gray-100 shadow-lg shadow-gray-50-500/40"
>
  <input
    class="mb-5 text-start w-full"
    type="file"
    id="fileUpload"
    autocomplete="off"
    #uploader
    hidden
    accept=".pdf"
    (change)="fileEvent($event)"
  />
  <button
    type="button"
    name="fileUpload"
    class="w-full"
    style="outline: none"
    *ngIf="!showFileLoaded"
    (click)="uploader.click()"
  >
    <div
      class="flex justify-between gap-2 items-center truncate whitespace-nowrap"
    >
      <p>{{ placeholder | translate }}</p>
      <img
        class="block absolute right-3"
        src="assets/img/upload/upload.svg"
        alt="Logo Cliente"
        rel="preload"
        width="20"
        height="20"
        as="image"
      />
    </div>
  </button>
  <div *ngIf="showFileLoaded" class="flex justify-between items-center w-full">
    <!-- <div class="text-color truncate">{{ fileLoaded }}</div> -->
    <label
      for="fileLoaded"
      [title]="fileLoaded"
      class="text-color truncate cursor-pointer"
      >{{ fileLoaded }}</label
    >
    <button
      (click)="changeFileEvent()"
      type="button"
      [ngClass]="{ 'w-auto': closeBigger, 'w-[24px]': !closeBigger }"
      #resetFileButton
    >
      <img
        class="block right-3"
        src="assets/img/cerrar-modal.svg"
        alt="Eliminar archivo"
        width="20"
        height="20"
        rel="preload"
        as="image"
      />
    </button>
  </div>
</div>
