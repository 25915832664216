export const ICON_LIST = [
  {
    name: 'SAVE-PROCESS',
    path: 'assets/img/upload/save-process.svg',
    svg: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"viewBox="0 0 24 24">
  <path id="Icon_awesome-save" data-name="Icon awesome-save" d="M23.247,7.5,18.753,3a2.571,2.571,0,0,0-1.818-.753H2.571A2.571,2.571,0,0,0,0,4.821V23.679A2.571,2.571,0,0,0,2.571,26.25H21.429A2.571,2.571,0,0,0,24,23.679V9.315A2.571,2.571,0,0,0,23.247,7.5ZM12,22.821a3.429,3.429,0,1,1,3.429-3.429A3.429,3.429,0,0,1,12,22.821ZM17.143,6.508v5.385a.643.643,0,0,1-.643.643H4.071a.643.643,0,0,1-.643-.643V6.321a.643.643,0,0,1,.643-.643H16.314a.643.643,0,0,1,.455.188l.186.186a.643.643,0,0,1,.188.455Z" transform="translate(0 -2.25)" fill="#fff"/>
</svg>

`,
  },
  {
    name: 'PLUS',
    path: 'assets/img/upload/plus.svg',
  },
  {
    name: 'SEARCH',
    path: 'assets/img/upload/search.svg',
  },
  {
    name: 'ARROW-RIGHT',
    path: 'assets/img/upload/arrow-right.svg',
    svg: `<svg width="20" height="20" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.502 11.7801C23.6591 11.6359 23.7845 11.4606 23.8704 11.2654C23.9562 11.0702 24.0005 10.8593 24.0005 10.6461C24.0005 10.4328 23.9562 10.2219 23.8704 10.0267C23.7845 9.83146 23.6591 9.65622 23.502 9.51205L13.7389 0.412048C13.4384 0.131847 13.0389 -0.0174898 12.6283 -0.00314331C12.2177 0.0112032 11.8296 0.188066 11.5494 0.488556C11.2692 0.789046 11.1198 1.18854 11.1342 1.59915C11.1485 2.00976 11.3255 2.39784 11.626 2.67804L18.5039 9.09106L1.5509 9.09106C1.15344 9.11126 0.778941 9.28337 0.504761 9.57184C0.23058 9.86031 0.0776367 10.2431 0.0776367 10.6411C0.0776367 11.039 0.23058 11.4218 0.504761 11.7103C0.778941 11.9987 1.15344 12.1708 1.5509 12.191L18.5039 12.191L11.626 18.5911C11.3255 18.8713 11.1485 19.2593 11.1342 19.67C11.1198 20.0806 11.2692 20.4801 11.5494 20.7805C11.8296 21.081 12.2177 21.2579 12.6283 21.2722C13.0389 21.2866 13.4384 21.1373 13.7389 20.8571L23.502 11.7801Z" fill="white"/>
</svg>`,
  },

  {
    name: 'ARROW-LEFT',
    path: 'assets/img/upload/arrow-left.svg',
    svg: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
</svg>
`
  }   
];
