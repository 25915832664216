<button
  class="rounded-3xl h-[45px] w-full tracking-normal text-center text-lg relative text-white px-3 shadow-lg disabled:opacity-50 flex justify-center items-center py-4 hover:opacity-90 font-semibold"
  [ngClass]="getButtonColor()"
  [disabled]="disabled || loading"
  type="button"
  (click)="clicked.emit()"
>
  {{ text }}

  <div
    *ngIf="loading"
    class="animate-spin h-5 w-5 text-white ml-2 absolute top-0 left-0 right-0 bottom-0 m-auto"
    style="margin: auto"
  >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  </div>
  <div
    class="w-12 h-[45px] absolute text-white rounded-full top-0 bottom-0 flex justify-center items-center shadow-2xl shadow-black"
    [class]="circlePosition === 'right' ? 'right-0' : 'left-0'"
    [ngClass]="getButtonColorLight()"
  >
    <div [innerHTML]="getIconSVGByName()"></div>
  </div>
</button>
