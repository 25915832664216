import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LocalStoreService } from '../../../commons/services/local-store.service';
import { StoreName } from '../../../commons/enums/store-name.enum';

@Component({
  selector: 'coface-confirm-online-form',

  templateUrl: './confirm-online-form.component.html',
  styleUrl: './confirm-online-form.component.scss',
})
export class ConfirmOnlineFormComponent implements OnInit {
  public homologationIdParam: string | null;
  public formId: string | null;
  public termAndConditions: boolean;
  public userId: number;
  public personType: string;
  constructor(
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private router: Router,
    private store: LocalStoreService
  ) {}

  ngOnInit(): void {
    this.homologationIdParam = this.dialogConfig.data?.homologationId;
    this.formId = this.dialogConfig.data?.formId;
    this.termAndConditions = this.dialogConfig.data?.isProcessingData;
    this.userId = this.dialogConfig.data?.officeId;
    this.personType = this.dialogConfig.data?.personType;
  }

  closeModal() {
    this.dialogRef.close();
  }

  public goDocuments() {
    this.closeModal();
    this.router.navigate(['../coface/homologacion/carga-documentos'], {
      queryParams: {
        homologationId: this.homologationIdParam,
      },
    });
  }

  public goOnlineForm() {
    this.store.set(StoreName.FORM_ID, this.formId);
    this.store.set(StoreName.HOMLOGATION_ID, this.homologationIdParam);
    this.store.set(StoreName.TERMS_AND_CONDITIONS, this.termAndConditions);
    this.store.set(StoreName.CUSTOMER_ID, this.userId);
    this.store.set(StoreName.PERSON_TYPE, this.personType);
    this.closeModal();
    this.router.navigate(['../coface/formularios-enlinea/informacion-general']);
  }
}
