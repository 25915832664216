import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'coface-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit, OnChanges {
  items = [
    {
      id: '10',
      name: '10',
    },
    {
      id: '20',
      name: '20',
    },
    {
      id: '50',
      name: '50',
    },
    {
      id: '100',
      name: '100',
    },
  ];

  public currentePageControl = new UntypedFormControl(1);
  public itemsPerPage = new UntypedFormControl(10);
  @Input() public totalElements: number;
  @Input() public currentPage: number;
  @Input() public itemsXPage: number;
  @Input() public event: boolean;
  @Input() public showTrash: boolean;
  @Input() public showItemsByPage: boolean = true;

  @Output() public changeItems = new EventEmitter<any>();
  @Output() public changePage = new EventEmitter<any>();
  @Output() public eventPaginator = new EventEmitter<any>();

  public totalPage: number;
  public page: number = 0;
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.showItemsByPage);

    this.itemsPerPage.setValue(changes['itemsXPage']?.currentValue, {
      emitEvent: false,
    });
    this.page = changes['currentPage']?.currentValue + 1;
    this.totalPage = Math.ceil(
      changes['totalElements']?.currentValue / +this.itemsPerPage.value
    );
  }
  ngOnInit(): void {}

  public onItemsChange(_) {
    this.changeItems.next(this.itemsPerPage.value);
  }

  public nextPage() {
    this.page = this.page += 1;
    this.changePage.next(+this.page);
  }
  public backPage() {
    this.page = this.page > 1 ? (this.page -= 1) : 1;

    this.changePage.next(+this.page);
  }

  public onKeyUp(event) {
    const valuePage = event.target.value;
    if (valuePage <= this.totalPage) {
      this.changePage.next(+event.target.value);
    }
  }
  public onEmitEvent() {
    this.eventPaginator.emit();
  }
}
